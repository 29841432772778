.Button-module__button__Jl3nl {
  display: inline-block;
  background: var(--cobalt-500);
  color: #fff;
  text-decoration: none;
  appearance: none;
  padding: 1rem 1.5rem;
  border-radius: 3.5rem;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2px;
  border: none;
  transition: background 100ms ease;
  position: relative;
  overflow: hidden;
}

.Button-module__button__Jl3nl[disabled] {
  cursor: default;
  opacity: 0.5;
  pointer-events: none;
}

.Button-module__block__T1NdZ {
  display: block;
  inline-size: 100%;
}

.Button-module__button__Jl3nl:hover {
  background: var(--cobalt-900);
}

.Button-module__button__Jl3nl:focus-visible {
  outline: 2px solid var(--cobalt-500-white-0);
  outline-offset: 2px;
}

.Button-module__invert__qFtQJ {
  background: #fff;
  color: var(--cobalt-500);
}

.Button-module__invert__qFtQJ:hover {
  background: var(--cobalt-150);
}

.Button-module__invert__qFtQJ:focus-visible {
  outline-color: #fff;
}

.Button-module__spinner__KugZo {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  inline-size: 100%;
  block-size: 100%;
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Button-module__spinner__KugZo svg {
  inline-size: 28px;
  block-size: 28px;
  animation: Button-module__spin__djL6J 0.8s infinite linear;
}

@keyframes Button-module__spin__djL6J {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
