.TextInput-module__input__8qcjd {
  appearance: none;
  background: var(--white-0-ink-750);
  border: 2px solid var(--ink-300);
  padding: 0.8rem 1rem;
  color: var(--ink-750-white-0);
  border-radius: 0.75rem;
  inline-size: 100%;
  font-size: 1.25rem;
  outline: none;
}

.TextInput-module__invalid__VB-dg {
  border-color: var(--error-red);
}

.TextInput-module__input__8qcjd:focus-visible {
  border-color: var(--cobalt-500-white-0);
}
