.PlanPrice-module__wasPrice__0iCYF {
  color: var(--cobalt-150-250);
}

/* Desktop - Small (1200px) */

@media (min-width: 75rem) {
  .PlanPrice-module__pricePlanText__XHIfm {
    font-size: 1.125rem;
  }
}
