.Badge-module__badge__TB5s6 {
  --background: var(--cobalt-425);
  --color: #fff;
  padding: 0.1rem 0.5rem;
  text-transform: uppercase;
  border-radius: 0.3rem;
  background: var(--background);
  color: var(--color);
}

.Badge-module__zest__aQ1sv {
  --background: var(--zest-500);
  --color: var(--ink-750);
}

.Badge-module__cobalt__5iUG7 {
  --background: var(--cobalt-425);
}
