.Plans-module__plans__JZ9Uc {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.Plans-module__plans__JZ9Uc ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.Plans-module__plans__JZ9Uc button {
  align-self: flex-start;
}

.Plans-module__collapseToggleButton__wfNu- {
  appearance: none;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  color: var(--cobalt-500-white-0);
  text-decoration: underline;
  font-weight: 600;
}

@media (min-width: 75rem) {
  .Plans-module__plans__JZ9Uc ul {
    gap: 2rem;
  }
}
