.DetailsForm-module__form__4m2ev {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.DetailsForm-module__error__wPVLd {
  color: var(--error-red);
}

.DetailsForm-module__turnstileWidget__KkPul {
  iframe {
    width: 100% !important;
  }
}
