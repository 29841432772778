.confirmation-module__page__hVodZ {
  display: flex;
  padding-block-end: 0;
}

.confirmation-module__header__WiXAi {
  flex: 1;
  padding-block-end: 2.5rem;
}

.confirmation-module__body__5s79A {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
}

.confirmation-module__body__5s79A img {
  max-inline-size: 16rem;
}

.confirmation-module__body__5s79A img,
.confirmation-module__downloadApp__1V2J9 {
  align-self: center;
}

.confirmation-module__description__PRFKT {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.confirmation-module__email__d6ZfJ {
  overflow-wrap: anywhere;
}

@media (min-width: 48em) {
  .confirmation-module__header__WiXAi {
    padding-block-end: 5rem;
  }
}
