.FeatureCarousel-module__featuresCarousel__ZQr4m {
  margin-block: 0;
  padding-block-start: 3rem;
}

.FeatureCarousel-module__featureItem__oH1e- {
  background-image: url('../../../public/img/feature-hite.svg');
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: top left;
  background-color: var(--cobalt-425);
  font-family: 'Obviously', sans-serif;
  padding: 0;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 22px;
  inline-size: var(--item-width);
  block-size: var(--item-height);
}

.FeatureCarousel-module__carouselTitle__Ppnlu {
  font-size: 0.875rem;
  text-align: center;
  padding: 1.5rem;
}

.FeatureCarousel-module__carouselImage__ORvGu {
  inline-size: 9.6rem;
  max-inline-size: 9.6rem;
  block-size: 17rem;
  max-block-size: 17rem;
  align-self: center;
}

.FeatureCarousel-module__imageOnly__q6-Oi {
  padding: 0;
  .FeatureCarousel-module__carouselTitle__Ppnlu {
    display: none;
  }

  .FeatureCarousel-module__carouselImage__ORvGu {
    inline-size: 100%;
    block-size: 100%;
    position: relative;
    inset-block-end: 0;
  }
}

/* Tablet (768px) */
@media (min-width: 48rem) {
  .FeatureCarousel-module__featuresCarousel__ZQr4m {
    :nth-child(n + 4) {
      display: none;
    }
    justify-content: center;
  }
}

/* Tablet - Landscape (902px) */

@media (min-width: 56.375rem) {
  .FeatureCarousel-module__featuresCarousel__ZQr4m {
    :nth-child(n + 4) {
      display: flex;
    }
    padding-inline: 0;
    overflow: visible;
    gap: 1rem;
  }
}

/* Desktop - Small (1200px) */

@media (min-width: 75rem) {
  .FeatureCarousel-module__featuresCarousel__ZQr4m {
    justify-content: flex-start;
    :nth-child(n + 4) {
      display: none;
    }
    gap: 2rem;
  }
}

/* Special for carousel */

@media (min-width: 84rem) {
  .FeatureCarousel-module__featuresCarousel__ZQr4m {
    :nth-child(n + 4) {
      display: flex;
    }
    padding-inline: 0;
    overflow: visible;
  }
}
