.PageContent-module__content__hKzPY {
  display: grid;
  margin-block-start: 3rem;
  grid-gap: 2rem;
}

@media (min-width: 48rem) {
  .PageContent-module__content__hKzPY {
    margin-block-start: 4rem;
    grid-column-gap: var(--gutter);
  }
}
