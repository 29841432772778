._index-module__page__VHY8U {
  position: relative;
  overflow: hidden;
  padding-block-end: 0;
}

._index-module__pageHeaderBody__h7O6Z {
  max-inline-size: var(--max-page-inline-size);
  padding-inline: var(--outer-gutter);
  display: flex;
  flex-direction: column;
  justify-content: normal;
  z-index: 1;
  gap: 0;
}

._index-module__pageHeaderHeading__4MjaJ {
  font-size: 1.6rem;
  margin-block-end: 2rem;
  text-wrap: nowrap;
}

._index-module__appName__SgB2H {
  color: var(--zest-500);
}

._index-module__content__pTdkr {
  display: flex;
  flex-direction: column;
}

._index-module__transformationDivider__Lnuhj {
  --outgoing-divider-color: var(--white-0-ink-750);
  --incoming-divider-color: var(--cobalt-50-550);
  z-index: 2;
}

._index-module__transformationContent__v-5Up {
  background-color: var(--cobalt-50-550);
  padding-block: 2.5rem;
  background-image: url('../../public/img/transformation-section-wave-light.svg');
  background-repeat: no-repeat;
  background-position: bottom center;
}

._index-module__transformationCarousel__6HKYM {
  padding-block-start: 2.5rem;
  padding-inline: 2rem;
  margin-inline: -2rem;
}

._index-module__awardList__CugMr {
  align-self: flex-start;
  inline-size: 21.875rem;
}

._index-module__featuresTitle__OIzLJ {
  color: var(--cobalt-500-white-0);
}

._index-module__featureList__O5Xt7 {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-block: 1rem;
}

._index-module__plans__CDX1S {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-block: 2.5rem;
}

._index-module__planList__K3Zh- {
  display: none;
}

._index-module__title__W-BcM {
  text-align: start;
}

._index-module__featureCarousel__ADfTp {
  padding-inline: 5rem;
  margin-inline: -5rem;
}

@media (prefers-color-scheme: dark) {
  ._index-module__headerSection__GL79F {
    color: var(--ink-750-white-0);
  }
}

/* Tablet (768px) */
@media (min-width: 48rem) {
  ._index-module__pageHeaderBody__h7O6Z {
    display: grid;
    grid-gap: 1rem;
  }

  ._index-module__pageHeaderHeading__4MjaJ {
    font-size: 2rem;
  }

  ._index-module__pageHeadingCopy__PTGsK {
    background-image: url('../../public/img/bc-vector.svg');
    background-repeat: no-repeat;
    background-position: top right;
    padding-inline-end: 2.375rem;
  }

  ._index-module__plans__CDX1S {
    align-self: start;
    grid-area: plans;
  }

  ._index-module__appScreenshot__mcmne {
    grid-area: screenshot;
    padding-inline: 0;
  }

  ._index-module__awardListContainer__xCsmQ {
    display: none;
  }

  ._index-module__featureCarousel__ADfTp {
    grid-row: 2;
    grid-column: 1 / 3;
    margin-inline: 0;
    padding-inline: 0;
  }

  ._index-module__featureList__O5Xt7 {
    display: none;
  }
}

/* Tablet - Landscape (902px) */

@media (min-width: 56.375rem) {
  ._index-module__backgroundHite__IFSOG {
    inline-size: 1173px;
  }

  ._index-module__header__shkvf h1 {
    margin-block-end: 3rem;
  }

  ._index-module__awardListContainer__xCsmQ {
    display: flex;
    justify-self: flex-end;
  }

  ._index-module__plans__CDX1S {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  ._index-module__awardList__CugMr {
    grid-column: 2;
    grid-row: 1;
  }
}

@media (min-width: 64rem) {
  ._index-module__pageHeaderBody__h7O6Z {
    grid-column-gap: 2.375rem;
  }
}

/* Desktop - Small (1200px) */

@media (min-width: 75rem) {
  ._index-module__pageHeaderBody__h7O6Z {
    grid-template-columns: auto auto 22rem;
    grid-template-rows: 6.25rem auto auto;
    margin-inline: auto;
    inline-size: 100%;
  }

  ._index-module__pageHeadingHeader__ACRne {
    margin-block-end: 0;
  }

  ._index-module__awardListContainer__xCsmQ {
    display: none;
  }

  ._index-module__planList__K3Zh- {
    display: block;
    grid-column: 3;
    grid-row: 1 / 3;
  }

  ._index-module__plansAndFeatures__Gi2aC {
    display: none;
  }

  ._index-module__featureCarousel__ADfTp {
    grid-column: 1 / 3;
    grid-row: 2;
  }

  ._index-module__transformationDivider__Lnuhj {
    --outgoing-divider-color: var(--cobalt-500-1000);
  }
}

@media (min-width: 84rem) {
  ._index-module__awardListContainer__xCsmQ {
    display: flex;
    justify-self: unset;
  }
}

@media (prefers-color-scheme: dark) {
  ._index-module__transformationContent__v-5Up {
    background-image: url('../../public/img/transformation-section-wave-dark.svg');
  }
}
