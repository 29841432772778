.RedeemCoupon-module__applyCoupon__QEes5,
.RedeemCoupon-module__couponApplied__t2uT0 {
  padding-block: 1rem;
  border-block: 1px solid var(--ink-200-700);
}

.RedeemCoupon-module__removeCoupon__NRY3X {
  font-weight: 600;
}

.RedeemCoupon-module__couponApplied__t2uT0 {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.RedeemCoupon-module__couponAppliedCode__BwhqE {
  text-transform: uppercase;
}

.RedeemCoupon-module__applyCouponSummary__JrpWj {
  list-style: none;
  cursor: pointer;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.RedeemCoupon-module__applyCouponSummary__JrpWj::-webkit-details-marker {
  display: none;
}

.RedeemCoupon-module__applyCouponSummaryText__6q6Dp,
.RedeemCoupon-module__couponAppliedText__tyqIH {
  flex-grow: 1;
}

.RedeemCoupon-module__arrowIcon__cYWEI,
.RedeemCoupon-module__checkIcon__aPLlW,
.RedeemCoupon-module__couponIconLine__DFzFY {
  stroke: var(--ink-750-white-0);
}

.RedeemCoupon-module__couponIconGlyph__lWgyX {
  fill: var(--ink-750-white-0);
}

.RedeemCoupon-module__couponApplied__t2uT0 .RedeemCoupon-module__couponIconLine__DFzFY,
.RedeemCoupon-module__couponApplied__t2uT0 .RedeemCoupon-module__checkIcon__aPLlW {
  stroke: var(--cobalt-500-250);
}

.RedeemCoupon-module__couponApplied__t2uT0 .RedeemCoupon-module__couponIconGlyph__lWgyX {
  fill: var(--cobalt-500-250);
}

.RedeemCoupon-module__applyCoupon__QEes5[open] .RedeemCoupon-module__arrowIcon__cYWEI {
  transform: rotate(180deg);
}

.RedeemCoupon-module__form__7kL7x {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  margin-block: 1rem;
  flex-wrap: wrap;
}

.RedeemCoupon-module__invalidCoupon__-Q7lu {
  flex: 1 0 100%;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.RedeemCoupon-module__invalidCoupon__-Q7lu,
.RedeemCoupon-module__form__7kL7x:has(.RedeemCoupon-module__invalidCoupon__-Q7lu) .RedeemCoupon-module__couponLabel__L-u9e {
  color: var(--error-red);
}

.RedeemCoupon-module__warningIcon__dsr1G {
  fill: var(--error-red);
}

.RedeemCoupon-module__couponField__7YfSi {
  flex: 1 0 auto;
}

.RedeemCoupon-module__couponLabel__L-u9e {
  font-size: 1.125rem;
}

.RedeemCoupon-module__couponInput__IETh5 {
  padding-block: 0.7rem;
}

/* Desktop - Small (1200px) */
@media (min-width: 75rem) {
  .RedeemCoupon-module__applyCoupon__QEes5,
  .RedeemCoupon-module__couponApplied__t2uT0 {
    border-block: 1px solid var(--cobalt-300);
  }

  .RedeemCoupon-module__couponLabel__L-u9e {
    color: #fff;
  }

  .RedeemCoupon-module__couponApplied__t2uT0 .RedeemCoupon-module__couponIconLine__DFzFY,
  .RedeemCoupon-module__couponApplied__t2uT0 .RedeemCoupon-module__checkIcon__aPLlW,
  .RedeemCoupon-module__arrowIcon__cYWEI,
  .RedeemCoupon-module__checkIcon__aPLlW,
  .RedeemCoupon-module__couponIconLine__DFzFY {
    stroke: #fff;
  }

  .RedeemCoupon-module__couponIconGlyph__lWgyX {
    fill: #fff;
  }

  .RedeemCoupon-module__formButton__452MG {
    background-color: var(--zest-500);
    color: var(--cobalt-500);
  }

  .RedeemCoupon-module__formButton__452MG:hover {
    background: var(--zest-600);
  }

  .RedeemCoupon-module__formButton__452MG:focus-visible {
    outline-color: var(--zest-600);
  }

  .RedeemCoupon-module__couponInput__IETh5:focus-visible {
    border-color: var(--ink-750-white-0);
  }
}

/* Desktop - Small (1200px) */

@media (min-width: 75rem) {
  .RedeemCoupon-module__invalidCoupon__-Q7lu,
  .RedeemCoupon-module__form__7kL7x:has(.RedeemCoupon-module__invalidCoupon__-Q7lu) .RedeemCoupon-module__couponLabel__L-u9e {
    color: #fff;
  }

  .RedeemCoupon-module__warningIcon__dsr1G {
    fill: #fff;
  }

  .RedeemCoupon-module__removeCoupon__NRY3X {
    color: inherit;
  }
}
