.PageHeader-module__pageHeader__3MkOM {
  background-color: var(--cobalt-450-1050);
  background-image: url('../../../public/img/page-header-bg-line-narrow-light.svg');
  background-repeat: no-repeat;
  background-position: top right 40px;
  background-size: 200px auto;
  color: #fff;
  padding-block-start: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
  position: relative;
}

.PageHeader-module__pageHeader__3MkOM p a {
  color: inherit;
}

.PageHeader-module__logo__0TpfY {
  inline-size: 3.31rem;
  display: block;
}

.PageHeader-module__logo__0TpfY:focus-visible {
  outline: 2px solid #fff;
  outline-offset: 8px;
  border-radius: 2px;
}

.PageHeader-module__logo__0TpfY svg {
  display: block;
  inline-size: 100%;
  fill: currentColor;
}

.PageHeader-module__pageHeader__3MkOM .PageHeader-module__divider__Y18nV {
  --outgoing-divider-color: var(--cobalt-450-1050);
  --incoming-divider-color: var(--white-0-ink-750);
}

.PageHeader-module__bg__SaLRr {
  display: none;
}

@media (min-width: 48rem) {
  .PageHeader-module__pageHeader__3MkOM {
    padding-block-start: 2.5rem;
    gap: 3rem;
    background-image: url('../../../public/img/page-header-bg-line-wide-light.svg');
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 789px auto;
  }

  .PageHeader-module__pageHeader__3MkOM .PageHeader-module__divider__Y18nV {
    --outgoing-divider-color: var(--cobalt-500-1000);
  }

  .PageHeader-module__bg__SaLRr {
    display: block;
    position: absolute;
    inset-block-start: 0;
    inset-inline-end: 0;
    inline-size: 50%;
    block-size: 100%;
    background-image: url('../../../public/img/page-header-bg-light.svg');
    background-repeat: no-repeat;
    background-position: center left;
    background-size: cover;
    z-index: 0;
  }

  .PageHeader-module__pageHeader__3MkOM.PageHeader-module__flourishes__740Hl .PageHeader-module__bg__SaLRr {
    background-image: url('../../../public/img/page-header-blush-swirl.svg'), url('../../../public/img/page-header-bg.svg');
    background-repeat: no-repeat;
    background-position:
      top 4rem right 20%,
      center left;
    background-size:
      87px auto,
      cover;
  }

  .PageHeader-module__logo__0TpfY {
    inline-size: 4.31rem;
  }
}

@media (prefers-color-scheme: dark) {
  .PageHeader-module__pageHeader__3MkOM {
    background-image: url('../../../public/img/page-header-bg-line-narrow-dark.svg');
  }
  .PageHeader-module__bg__SaLRr {
    background-image: url('../../../public/img/page-header-bg-dark.svg');
  }
}
