.FeatureList-module__features__GDyEt {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.FeatureList-module__feature__Pa3Wh {
  /* 32px image width + 24px gutter = 56px = 3.5rem */
  padding-inline-start: 3.5rem;
  background: url('../../../public/img/tick.svg') no-repeat 0 0;
  /* ensures the full image displays when on a single line */
  min-block-size: 2rem;
}
