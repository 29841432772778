.PaymentForm-module__payment__fd78H {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.PaymentForm-module__error__imJE4 {
  text-align: center;
  color: var(--error-red);
}

.PaymentForm-module__paymentButton__t-M7B {
  max-inline-size: 25rem;
  align-self: center;
}

@media (prefers-color-scheme: dark) {
  .PaymentForm-module__payment__fd78H {
    border-radius: 1rem;
    background: #fff;
    padding: 1.5rem;
  }
}
