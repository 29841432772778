.Message-module__message__TXgZQ {
  --background: var(--ink-200-700);
  --color: inherit;
  padding: 1.5rem;
  border-radius: 1rem;
  color: var(--color);
  background: var(--background);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.Message-module__message__TXgZQ a {
  color: inherit;
}

.Message-module__message__TXgZQ.Message-module__error__DlKXp {
  --background: var(--error-red);
  --color: #fff;
}
