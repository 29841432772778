.PageWidth-module__pageWidth__F6OTU {
  padding-inline: var(--outer-gutter);
  max-inline-size: var(--max-page-inline-size);
  margin-inline: auto;
  inline-size: 100%;
}

.PageWidth-module__narrow__jJO-f {
  max-inline-size: var(--max-page-inline-size-narrow);
}
