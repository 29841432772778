.Plan-module__plan__wzyx8 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 2px solid var(--cobalt-300);
  gap: 0.625rem;
  background: var(--cobalt-500);
  border-radius: 1.25rem;
  color: #fff;
  padding: 1rem;
  border: 2px solid var(--cobalt-300);
}.Plan-module__planDetails__TAKrM {
  display: grid;
  inline-size: 100%;
  grid-template-areas: 'body radio';
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  column-gap: 0.5rem;
}.Plan-module__badges__zya-g {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-block-end: 0.3rem;
}.Plan-module__badges__zya-g:empty {
  display: none;
}.Plan-module__plan__wzyx8:has(input[type='radio']:checked) {
  border-color: var(--zest-500);
}.Plan-module__body__e-4N0 {
  grid-area: body;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}.Plan-module__heading__g53JB {
  text-transform: capitalize;
  font-size: 1.625rem;
}.Plan-module__pricePerMonth__7asv0 {
  color: var(--cobalt-150-250);
}.Plan-module__freeTrialMessageContainer__kZA9O {
  border-top: solid 2px var(--cobalt-300);
  margin-block-start: 1rem;
  padding-block-start: 0.75rem;
  inline-size: 100%;
}.Plan-module__freeTrialMessage__HuXhL {
  display: flex;
  gap: 1rem;
  align-items: center;
  background-image: url('../../../public/img/bell.svg');
  background-repeat: no-repeat;
  background-position: center left;
  padding-inline-start: 40px;
  padding-block: 0.2rem;
}.Plan-module__plan__wzyx8 input[type='radio'] {
  justify-self: end;
  grid-area: radio;
  /* Older versions of Safari require the -webkit- prefix to be explicitly defined */
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  inline-size: 2rem;
  block-size: 2rem;
  border-radius: 100%;
  background: var(--cobalt-500-1000);
  border: 2px solid var(--cobalt-300);
  transition-property: background-color, border-color;
  transition-duration: 100ms;
  transition-timing-function: ease;
  display: grid;
  place-items: center;
}.Plan-module__plan__wzyx8 input[type='radio']:focus-visible {
  outline: 2px solid var(--zest-500);
  outline-offset: 2px;
}.Plan-module__plan__wzyx8 input[type='radio']:checked {
  background-color: var(--zest-500);
  border-color: var(--zest-500);
}.Plan-module__plan__wzyx8 input[type='radio']:checked::after {
  content: '';
  display: block;
  margin-block-start: 1px; /* sorts out slight vertical mis-alignment */
  inline-size: 12px;
  block-size: 9px;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4L4.5 7.5L11 1' stroke='%2300398a' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
}@media (min-width: 48rem) {
  .Plan-module__heading__g53JB {
    font-size: 1.875rem;
  }
}@media (min-width: 75rem) {
  .Plan-module__heading__g53JB {
    font-size: 1.5rem;
  }

  .Plan-module__pricePerMonth__7asv0 {
    font-size: 1.125rem;
  }
}

.PlanSkeleton-module__skeleton__FCGs2 {
  display: flex;
  border: none;
  background: var(--ink-750-white-0);
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  position: relative;
  overflow: hidden;
  opacity: 0.1;
}

.PlanSkeleton-module__skeleton__FCGs2::after {
  content: '';
  display: block;
  position: absolute;
  inset: 0;
  background: linear-gradient(-60deg, #fff0 0, #fff0 10%, #fff, #fff0 90%, #fff0 100%);
  transform: translateX(-100%);
  animation: PlanSkeleton-module__shimmer__fjI6p 1.5s infinite ease;
  opacity: 0.5;
}

.PlanSkeleton-module__title__fkyYJ,
.PlanSkeleton-module__price__yPMV2 {
  background: var(--white-0-ink-750);
  border-radius: 0.4rem;
}

.PlanSkeleton-module__title__fkyYJ {
  block-size: 1.8rem;
  inline-size: 8.3rem;
}

.PlanSkeleton-module__price__yPMV2 {
  block-size: 1.5rem;
  inline-size: 6.2rem;
}

@keyframes PlanSkeleton-module__shimmer__fjI6p {
  100% {
    transform: translateX(100%);
  }
}
