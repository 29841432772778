.Footer-module__footer__XwGCD {
  border-top: 1px solid var(--ink-200-700);
  color: var(--ink-525-white-0);
  padding-block: 4rem;
  padding-inline: var(--outer-gutter);
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  letter-spacing: 1px;
}

.Footer-module__footer__XwGCD a {
  color: inherit;
}

.Footer-module__footer__XwGCD a:hover {
  color: var(--cobalt-500-white-0);
}

@media (min-width: 50rem) {
  .Footer-module__footer__XwGCD {
    flex-direction: row;
    gap: 1.5rem;
  }

  .Footer-module__copyright__Yi0Hd {
    margin-inline-start: auto;
  }
}
