.Divider-module__divider__bKbum {
  inline-size: 100%;
  block-size: 0;
  display: flex;
  justify-content: space-between;
  position: relative;

  --divider-curve-size: 40px;
  /* Fall back to transparent so it doesn't look broken if the vars aren't set */
  --incoming-divider-color: transparent;
  --outgoing-divider-color: var(--incoming-divider-color);
}

.Divider-module__divider__bKbum svg {
  inline-size: var(--divider-curve-size);
  block-size: var(--divider-curve-size);
  display: block;
  position: absolute;
}

.Divider-module__divider__bKbum svg:first-child {
  fill: var(--incoming-divider-color);
  /* 40px => -40px */
  inset-block-start: calc(var(--divider-curve-size) * -1);
  inset-inline-start: 0;
}

.Divider-module__divider__bKbum svg:last-child {
  fill: var(--outgoing-divider-color);
  inset-block-start: 0;
  inset-inline-end: 0;
}

@media (min-width: 48rem) {
  .Divider-module__divider__bKbum {
    --divider-curve-size: 50px;
  }
}
