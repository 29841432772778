/* css-modules-plugin-ns:app/root.module.css?css-modules-plugin-compiled-css */
.root-module__root__vBkJw {
  --cobalt-50: #e1eeff;
  --cobalt-50-550: #e1eeff;
  --cobalt-150: #c2daff;
  --cobalt-150-250: #c2daff;
  --cobalt-300: #609df1;
  --cobalt-400-800: #4a91f3;
  --cobalt-425: #1368de;
  --cobalt-450-1050: #0e60d2;
  --cobalt-500: #0053c7;
  --cobalt-500-white-0: #0053c7;
  --cobalt-500-250: #0053c7;
  --cobalt-500-1000: #0053c7;
  --cobalt-850: #1050ab;
  --cobalt-900: #1a4d94;
  --cobalt-100-900: #cce2ff;
  --ink-100-600: #edf3fb;
  --ink-50-550: #f6f9fd;
  --ink-200-700: #e6eef9;
  --ink-300: #b2c2d9;
  --ink-525: #60769a;
  --ink-525-500: #60769a;
  --ink-525-white-0: #60769a;
  --ink-750-white-0: #18335b;
  --ink-750: #18335b;
  --ink-750-950: #18335b;
  --white-0-ink-750: #fff;
  --aqua-500: #00cfda;
  --blush-500: #f6c9c7;
  --zest-500: #d7fd8c;
  --zest-600: #cbf971;
  --error-red: #eb5743;
  --gutter: 2rem;
  --outer-gutter: 1.5rem;
  --max-page-inline-size: calc(80rem + (var(--outer-gutter) * 2));
  --max-page-inline-size-narrow: calc(37.5rem + (var(--outer-gutter) * 2));
  font-size: 16px;
  font-family: "QanelasSoft", sans-serif;
  line-height: 1.375;
  color: var(--ink-750-white-0);
  background: var(--white-0-ink-750);
  display: flex;
  flex-direction: column;
  accent-color: var(--cobalt-500-white-0);
  -webkit-font-smoothing: antialiased;
}
.root-module__page__9g5fU {
  flex: 1;
  display: flex;
}
.root-module__page__9g5fU > * {
  flex: 1;
}
a {
  color: var(--cobalt-500-white-0);
}
@media (prefers-color-scheme: dark) {
  .root-module__root__vBkJw {
    --cobalt-50-550: #275faf;
    --cobalt-150-250: #8bb9ff;
    --cobalt-400-800: #1859b6;
    --cobalt-450-1050: #03347a;
    --cobalt-500-white-0: #fff;
    --cobalt-500-250: #8bb9ff;
    --cobalt-500-1000: #00398a;
    --cobalt-100-900: #1a4d94;
    --ink-50-550: #25426c;
    --ink-200-700: #38567d;
    --ink-525-white-0: #fff;
    --ink-525-500: #90a4c5;
    --ink-525-white-0: #fff;
    --ink-750-white-0: #fff;
    --ink-750-950: #112b51;
    --white-0-ink-750: #18335b;
    --error-red: #ff7968;
  }
}
@media (min-width: 24rem) {
  .root-module__root__vBkJw {
    --outer-gutter: 2rem;
  }
}
@media (min-width: 48rem) {
  .root-module__root__vBkJw {
    --outer-gutter: 5rem;
  }
}

/* css-modules-plugin-ns:app/components/Footer.module.css?css-modules-plugin-compiled-css */
.Footer-module__footer__XwGCD {
  border-top: 1px solid var(--ink-200-700);
  color: var(--ink-525-white-0);
  padding-block: 4rem;
  padding-inline: var(--outer-gutter);
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  letter-spacing: 1px;
}
.Footer-module__footer__XwGCD a {
  color: inherit;
}
.Footer-module__footer__XwGCD a:hover {
  color: var(--cobalt-500-white-0);
}
@media (min-width: 50rem) {
  .Footer-module__footer__XwGCD {
    flex-direction: row;
    gap: 1.5rem;
  }
  .Footer-module__copyright__Yi0Hd {
    margin-inline-start: auto;
  }
}

/* css-modules-plugin-ns:app/components/Text.module.css?css-modules-plugin-compiled-css */
.Text-module__sm__t84ae {
  font-size: 0.875rem;
}
.Text-module__md__IPpti {
  font-size: 1rem;
}
.Text-module__lg__LHcPS {
  font-size: 1.125rem;
}
.Text-module__xl__CnNx2 {
  font-size: 1.25rem;
}
.Text-module__xxl__YEoNX {
  font-size: 1.5rem;
}
.Text-module__xxxl__P-3Ag {
  font-size: 1.75rem;
}
.Text-module__w400__79VdL {
  font-weight: 400;
}
.Text-module__w500__ihpD- {
  font-weight: 500;
}
.Text-module__w600__3AuP0 {
  font-weight: 600;
}
@media (min-width: 48rem) {
  .Text-module__sm__t84ae {
    font-size: 1rem;
  }
  .Text-module__lg__LHcPS {
    font-size: 1.25rem;
  }
  .Text-module__xl__CnNx2 {
    font-size: 1.5rem;
  }
}

/* css-modules-plugin-ns:app/components/ErrorPage.module.css?css-modules-plugin-compiled-css */
.ErrorPage-module__page__Rv5ug {
  background: var(--ink-50-550);
  padding: 3.5rem;
}
.ErrorPage-module__body__4Xiqi {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  text-align: center;
  max-inline-size: 32rem;
  margin-inline-start: auto;
  margin-inline-end: auto;
}
.ErrorPage-module__heading__rNor3 {
  color: var(--cobalt-500-white-0);
}
@media (min-width: 48rem) {
  .ErrorPage-module__page__Rv5ug {
    padding: 5.625rem;
  }
}

/* css-modules-plugin-ns:app/components/Heading.module.css?css-modules-plugin-compiled-css */
.Heading-module__h1__HaaMh,
.Heading-module__h2__8vNhp,
.Heading-module__h3__AV0hZ {
  font-family: "Obviously", sans-serif;
  font-weight: 600;
  line-height: 1.1;
}
.Heading-module__h1__HaaMh {
  font-size: 1.75rem;
}
.Heading-module__h2__8vNhp {
  font-size: 1.5rem;
}
.Heading-module__h3__AV0hZ {
  font-size: 1.25rem;
  letter-spacing: 0;
}
@media (min-width: 48rem) {
  .Heading-module__h1__HaaMh {
    font-size: 2.75rem;
  }
  .Heading-module__h2__8vNhp {
    font-size: 2rem;
  }
  .Heading-module__h3__AV0hZ {
    font-size: 1.6rem;
  }
}

/* css-modules-plugin-ns:app/components/Button.module.css?css-modules-plugin-compiled-css */
.Button-module__button__Jl3nl {
  display: inline-block;
  background: var(--cobalt-500);
  color: #fff;
  text-decoration: none;
  appearance: none;
  padding: 1rem 1.5rem;
  border-radius: 3.5rem;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2px;
  border: none;
  transition: background 100ms ease;
  position: relative;
  overflow: hidden;
}
.Button-module__button__Jl3nl[disabled] {
  cursor: default;
  opacity: 0.5;
  pointer-events: none;
}
.Button-module__block__T1NdZ {
  display: block;
  inline-size: 100%;
}
.Button-module__button__Jl3nl:hover {
  background: var(--cobalt-900);
}
.Button-module__button__Jl3nl:focus-visible {
  outline: 2px solid var(--cobalt-500-white-0);
  outline-offset: 2px;
}
.Button-module__invert__qFtQJ {
  background: #fff;
  color: var(--cobalt-500);
}
.Button-module__invert__qFtQJ:hover {
  background: var(--cobalt-150);
}
.Button-module__invert__qFtQJ:focus-visible {
  outline-color: #fff;
}
.Button-module__spinner__KugZo {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  inline-size: 100%;
  block-size: 100%;
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Button-module__spinner__KugZo svg {
  inline-size: 28px;
  block-size: 28px;
  animation: Button-module__spin__djL6J 0.8s infinite linear;
}
@keyframes Button-module__spin__djL6J {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* css-modules-plugin-ns:app/components/page/Page.module.css?css-modules-plugin-compiled-css */
.Page-module__page__U-Y4L {
  padding-block-end: 2.5rem;
}
@media (min-width: 48rem) {
  .Page-module__page__U-Y4L {
    padding-block-end: 5rem;
  }
}

/* css-modules-plugin-ns:app/components/pageHeader/PageHeader.module.css?css-modules-plugin-compiled-css */
.PageHeader-module__pageHeader__3MkOM {
  background-color: var(--cobalt-450-1050);
  background-image: url(/build/_assets/page-header-bg-line-narrow-light-BH72NHSD.svg);
  background-repeat: no-repeat;
  background-position: top right 40px;
  background-size: 200px auto;
  color: #fff;
  padding-block-start: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
  position: relative;
}
.PageHeader-module__pageHeader__3MkOM p a {
  color: inherit;
}
.PageHeader-module__logo__0TpfY {
  inline-size: 3.31rem;
  display: block;
}
.PageHeader-module__logo__0TpfY:focus-visible {
  outline: 2px solid #fff;
  outline-offset: 8px;
  border-radius: 2px;
}
.PageHeader-module__logo__0TpfY svg {
  display: block;
  inline-size: 100%;
  fill: currentColor;
}
.PageHeader-module__pageHeader__3MkOM .PageHeader-module__divider__Y18nV {
  --outgoing-divider-color: var(--cobalt-450-1050);
  --incoming-divider-color: var(--white-0-ink-750);
}
.PageHeader-module__bg__SaLRr {
  display: none;
}
@media (min-width: 48rem) {
  .PageHeader-module__pageHeader__3MkOM {
    padding-block-start: 2.5rem;
    gap: 3rem;
    background-image: url(/build/_assets/page-header-bg-line-wide-light-QTRAGAZW.svg);
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 789px auto;
  }
  .PageHeader-module__pageHeader__3MkOM .PageHeader-module__divider__Y18nV {
    --outgoing-divider-color: var(--cobalt-500-1000);
  }
  .PageHeader-module__bg__SaLRr {
    display: block;
    position: absolute;
    inset-block-start: 0;
    inset-inline-end: 0;
    inline-size: 50%;
    block-size: 100%;
    background-image: url(/build/_assets/page-header-bg-light-VA5G2NOX.svg);
    background-repeat: no-repeat;
    background-position: center left;
    background-size: cover;
    z-index: 0;
  }
  .PageHeader-module__pageHeader__3MkOM.PageHeader-module__flourishes__740Hl .PageHeader-module__bg__SaLRr {
    background-image: url(/build/_assets/page-header-blush-swirl-MMEIYTGR.svg), url(/build/_assets/page-header-bg-QL3YRYTH.svg);
    background-repeat: no-repeat;
    background-position: top 4rem right 20%, center left;
    background-size: 87px auto, cover;
  }
  .PageHeader-module__logo__0TpfY {
    inline-size: 4.31rem;
  }
}
@media (prefers-color-scheme: dark) {
  .PageHeader-module__pageHeader__3MkOM {
    background-image: url(/build/_assets/page-header-bg-line-narrow-dark-FOT6WRPD.svg);
  }
  .PageHeader-module__bg__SaLRr {
    background-image: url(/build/_assets/page-header-bg-dark-A2A2S7NU.svg);
  }
}

/* css-modules-plugin-ns:app/components/Divider.module.css?css-modules-plugin-compiled-css */
.Divider-module__divider__bKbum {
  inline-size: 100%;
  block-size: 0;
  display: flex;
  justify-content: space-between;
  position: relative;
  --divider-curve-size: 40px;
  --incoming-divider-color: transparent;
  --outgoing-divider-color: var(--incoming-divider-color);
}
.Divider-module__divider__bKbum svg {
  inline-size: var(--divider-curve-size);
  block-size: var(--divider-curve-size);
  display: block;
  position: absolute;
}
.Divider-module__divider__bKbum svg:first-child {
  fill: var(--incoming-divider-color);
  inset-block-start: calc(var(--divider-curve-size) * -1);
  inset-inline-start: 0;
}
.Divider-module__divider__bKbum svg:last-child {
  fill: var(--outgoing-divider-color);
  inset-block-start: 0;
  inset-inline-end: 0;
}
@media (min-width: 48rem) {
  .Divider-module__divider__bKbum {
    --divider-curve-size: 50px;
  }
}

/* css-modules-plugin-ns:app/components/page/PageWidth.module.css?css-modules-plugin-compiled-css */
.PageWidth-module__pageWidth__F6OTU {
  padding-inline: var(--outer-gutter);
  max-inline-size: var(--max-page-inline-size);
  margin-inline: auto;
  inline-size: 100%;
}
.PageWidth-module__narrow__jJO-f {
  max-inline-size: var(--max-page-inline-size-narrow);
}

/* css-modules-plugin-ns:app/routes/confirmation.module.css?css-modules-plugin-compiled-css */
.confirmation-module__page__hVodZ {
  display: flex;
  padding-block-end: 0;
}
.confirmation-module__header__WiXAi {
  flex: 1;
  padding-block-end: 2.5rem;
}
.confirmation-module__body__5s79A {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
}
.confirmation-module__body__5s79A img {
  max-inline-size: 16rem;
}
.confirmation-module__body__5s79A img,
.confirmation-module__downloadApp__1V2J9 {
  align-self: center;
}
.confirmation-module__description__PRFKT {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.confirmation-module__email__d6ZfJ {
  overflow-wrap: anywhere;
}
@media (min-width: 48em) {
  .confirmation-module__header__WiXAi {
    padding-block-end: 5rem;
  }
}

/* css-modules-plugin-ns:app/components/confirmation-route/FireworksAnimation.module.css?css-modules-plugin-compiled-css */
.FireworksAnimation-module__fireworks__AKSXa {
  position: absolute;
  inset-block-start: -200px;
  inset-inline-start: -20px;
  inline-size: 50%;
  max-inline-size: 200px;
}
.FireworksAnimation-module__fireworks__AKSXa:last-child {
  inset-block-start: -180px;
  inset-inline-start: auto;
  inset-inline-end: -20px;
}
@media (prefers-reduced-motion: reduce) {
  .FireworksAnimation-module__fireworks__AKSXa {
    display: none;
  }
}
@media (min-width: 48em) {
  .FireworksAnimation-module__fireworks__AKSXa {
    inset-inline-start: 0;
    inset-block-start: -100px;
  }
  .FireworksAnimation-module__fireworks__AKSXa:last-child {
    inset-inline-end: 0;
    inset-block-start: -80px;
  }
}

/* css-modules-plugin-ns:app/components/pageHeader/PageHeaderBody.module.css?css-modules-plugin-compiled-css */
:where(.PageHeaderBody-module__body__-KVzw) {
  max-inline-size: var(--max-page-inline-size-narrow);
  padding-inline: var(--outer-gutter);
  gap: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  inline-size: 100%;
  z-index: 1;
}

/* css-modules-plugin-ns:app/components/pageHeader/PageHeaderCopy.module.css?css-modules-plugin-compiled-css */
.PageHeaderCopy-module__copy__NN5sp {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  position: relative;
  z-index: 1;
}
@media (min-width: 48rem) {
  .PageHeaderCopy-module__copy__NN5sp {
    gap: 0.75rem;
  }
}
@media (min-width: 56.375rem) {
  .PageHeaderCopy-module__copy__NN5sp {
    background-image: none;
    padding-inline-end: 0;
  }
}

/* css-modules-plugin-ns:app/routes/existing-subscription.module.css?css-modules-plugin-compiled-css */
.existing-subscription-module__email__Oo1NA {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

/* css-modules-plugin-ns:app/components/page/PageContent.module.css?css-modules-plugin-compiled-css */
.PageContent-module__content__hKzPY {
  display: grid;
  margin-block-start: 3rem;
  grid-gap: 2rem;
}
@media (min-width: 48rem) {
  .PageContent-module__content__hKzPY {
    margin-block-start: 4rem;
    grid-column-gap: var(--gutter);
  }
}

/* css-modules-plugin-ns:app/components/form/FormField.module.css?css-modules-plugin-compiled-css */
.FormField-module__formField__i-NKb {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

/* css-modules-plugin-ns:app/components/form/Checkbox.module.css?css-modules-plugin-compiled-css */
.Checkbox-module__checkbox__Xi-nt {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
}
.Checkbox-module__checkbox__Xi-nt input[type=checkbox] {
  appearance: none;
  margin: 0;
  flex-shrink: 0;
  inline-size: 1.5rem;
  block-size: 1.5rem;
  background: var(--ink-50-550);
  border: 2px solid var(--ink-300);
  border-radius: 0.375rem;
  display: grid;
  place-items: center;
  transition: 100ms ease;
}
.Checkbox-module__checkbox__Xi-nt input[type=checkbox]:checked {
  background-color: var(--cobalt-500-white-0);
  border-color: var(--cobalt-500-white-0);
}
.Checkbox-module__checkbox__Xi-nt input[type=checkbox]:checked::after {
  content: "";
  display: block;
  margin-top: 1px;
  inline-size: 12px;
  block-size: 9px;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4L4.5 7.5L11 1' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
}
@media (prefers-color-scheme: dark) {
  .Checkbox-module__checkbox__Xi-nt input[type=checkbox]:checked::after {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4L4.5 7.5L11 1' stroke='%230053c7' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  }
}
.Checkbox-module__checkbox__Xi-nt a {
  color: inherit;
  font-weight: 600;
  text-decoration: underline;
}
.Checkbox-module__checkbox__Xi-nt a:hover {
  color: var(--cobalt-500-white-0);
}

/* css-modules-plugin-ns:app/components/form/Label.module.css?css-modules-plugin-compiled-css */
.Label-module__label__gwzIL {
  display: block;
  color: var(--cobalt-500-white-0);
}

/* css-modules-plugin-ns:app/components/form/TextInput.module.css?css-modules-plugin-compiled-css */
.TextInput-module__input__8qcjd {
  appearance: none;
  background: var(--white-0-ink-750);
  border: 2px solid var(--ink-300);
  padding: 0.8rem 1rem;
  color: var(--ink-750-white-0);
  border-radius: 0.75rem;
  inline-size: 100%;
  font-size: 1.25rem;
  outline: none;
}
.TextInput-module__invalid__VB-dg {
  border-color: var(--error-red);
}
.TextInput-module__input__8qcjd:focus-visible {
  border-color: var(--cobalt-500-white-0);
}

/* css-modules-plugin-ns:app/components/Message.module.css?css-modules-plugin-compiled-css */
.Message-module__message__TXgZQ {
  --background: var(--ink-200-700);
  --color: inherit;
  padding: 1.5rem;
  border-radius: 1rem;
  color: var(--color);
  background: var(--background);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.Message-module__message__TXgZQ a {
  color: inherit;
}
.Message-module__message__TXgZQ.Message-module__error__DlKXp {
  --background: var(--error-red);
  --color: #fff;
}

/* css-modules-plugin-ns:app/components/details-route/DetailsForm.module.css?css-modules-plugin-compiled-css */
.DetailsForm-module__form__4m2ev {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.DetailsForm-module__error__wPVLd {
  color: var(--error-red);
}
.DetailsForm-module__turnstileWidget__KkPul {
  iframe { width: 100% !important; };
}

/* css-modules-plugin-ns:app/components/form/FormFieldDescription.module.css?css-modules-plugin-compiled-css */
.FormFieldDescription-module__description__OlSFC {
  color: var(--ink-525-500);
}

/* css-modules-plugin-ns:app/components/pageHeader/PageHeaderMessage.module.css?css-modules-plugin-compiled-css */
.PageHeaderMessage-module__pageHeaderMessage__X3RhQ {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  border: 1px solid var(--cobalt-400-800);
  padding: 1rem 0.6rem;
  border-radius: 1.125rem;
  background-color: var(--cobalt-500-1000);
  color: var(--cobalt-50);
}
.PageHeaderMessage-module__icon__xqxN9 {
  inline-size: 2.25rem;
  flex-shrink: 0;
}
.PageHeaderMessage-module__icon__xqxN9 path,
.PageHeaderMessage-module__icon__xqxN9 circle {
  stroke: currentColor;
}

/* css-modules-plugin-ns:app/components/payment-route/PaymentForm.module.css?css-modules-plugin-compiled-css */
.PaymentForm-module__payment__fd78H {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.PaymentForm-module__error__imJE4 {
  text-align: center;
  color: var(--error-red);
}
.PaymentForm-module__paymentButton__t-M7B {
  max-inline-size: 25rem;
  align-self: center;
}
@media (prefers-color-scheme: dark) {
  .PaymentForm-module__payment__fd78H {
    border-radius: 1rem;
    background: #fff;
    padding: 1.5rem;
  }
}

/* css-modules-plugin-ns:app/routes/payment.module.css?css-modules-plugin-compiled-css */
.payment-module__body__i9Twx {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.payment-module__payment__93tbm {
  inline-size: 100%;
  max-inline-size: 39rem;
}

/* css-modules-plugin-ns:app/components/index-route/FeatureList.module.css?css-modules-plugin-compiled-css */
.FeatureList-module__features__GDyEt {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.FeatureList-module__feature__Pa3Wh {
  padding-inline-start: 3.5rem;
  background: url(/build/_assets/tick-KN4QIPZB.svg) no-repeat 0 0;
  min-block-size: 2rem;
}

/* css-modules-plugin-ns:app/components/page/PageTitle.module.css?css-modules-plugin-compiled-css */
.PageTitle-module__title__7-XwK {
  color: var(--cobalt-500-white-0);
}
.PageTitle-module__centred__x6IXA {
  text-align: center;
}

/* css-modules-plugin-ns:app/routes/_index.module.css?css-modules-plugin-compiled-css */
._index-module__page__VHY8U {
  position: relative;
  overflow: hidden;
  padding-block-end: 0;
}
._index-module__pageHeaderBody__h7O6Z {
  max-inline-size: var(--max-page-inline-size);
  padding-inline: var(--outer-gutter);
  display: flex;
  flex-direction: column;
  justify-content: normal;
  z-index: 1;
  gap: 0;
}
._index-module__pageHeaderHeading__4MjaJ {
  font-size: 1.6rem;
  margin-block-end: 2rem;
  text-wrap: nowrap;
}
._index-module__appName__SgB2H {
  color: var(--zest-500);
}
._index-module__content__pTdkr {
  display: flex;
  flex-direction: column;
}
._index-module__transformationDivider__Lnuhj {
  --outgoing-divider-color: var(--white-0-ink-750);
  --incoming-divider-color: var(--cobalt-50-550);
  z-index: 2;
}
._index-module__transformationContent__v-5Up {
  background-color: var(--cobalt-50-550);
  padding-block: 2.5rem;
  background-image: url(/build/_assets/transformation-section-wave-light-RATQQLC5.svg);
  background-repeat: no-repeat;
  background-position: bottom center;
}
._index-module__transformationCarousel__6HKYM {
  padding-block-start: 2.5rem;
  padding-inline: 2rem;
  margin-inline: -2rem;
}
._index-module__awardList__CugMr {
  align-self: flex-start;
  inline-size: 21.875rem;
}
._index-module__featuresTitle__OIzLJ {
  color: var(--cobalt-500-white-0);
}
._index-module__featureList__O5Xt7 {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-block: 1rem;
}
._index-module__plans__CDX1S {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-block: 2.5rem;
}
._index-module__planList__K3Zh- {
  display: none;
}
._index-module__title__W-BcM {
  text-align: start;
}
._index-module__featureCarousel__ADfTp {
  padding-inline: 5rem;
  margin-inline: -5rem;
}
@media (prefers-color-scheme: dark) {
  ._index-module__headerSection__GL79F {
    color: var(--ink-750-white-0);
  }
}
@media (min-width: 48rem) {
  ._index-module__pageHeaderBody__h7O6Z {
    display: grid;
    grid-gap: 1rem;
  }
  ._index-module__pageHeaderHeading__4MjaJ {
    font-size: 2rem;
  }
  ._index-module__pageHeadingCopy__PTGsK {
    background-image: url(/build/_assets/bc-vector-DUOWYIKR.svg);
    background-repeat: no-repeat;
    background-position: top right;
    padding-inline-end: 2.375rem;
  }
  ._index-module__plans__CDX1S {
    align-self: start;
    grid-area: plans;
  }
  ._index-module__appScreenshot__mcmne {
    grid-area: screenshot;
    padding-inline: 0;
  }
  ._index-module__awardListContainer__xCsmQ {
    display: none;
  }
  ._index-module__featureCarousel__ADfTp {
    grid-row: 2;
    grid-column: 1 / 3;
    margin-inline: 0;
    padding-inline: 0;
  }
  ._index-module__featureList__O5Xt7 {
    display: none;
  }
}
@media (min-width: 56.375rem) {
  ._index-module__backgroundHite__IFSOG {
    inline-size: 1173px;
  }
  ._index-module__header__shkvf h1 {
    margin-block-end: 3rem;
  }
  ._index-module__awardListContainer__xCsmQ {
    display: flex;
    justify-self: flex-end;
  }
  ._index-module__plans__CDX1S {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  ._index-module__awardList__CugMr {
    grid-column: 2;
    grid-row: 1;
  }
}
@media (min-width: 64rem) {
  ._index-module__pageHeaderBody__h7O6Z {
    grid-column-gap: 2.375rem;
  }
}
@media (min-width: 75rem) {
  ._index-module__pageHeaderBody__h7O6Z {
    grid-template-columns: auto auto 22rem;
    grid-template-rows: 6.25rem auto auto;
    margin-inline: auto;
    inline-size: 100%;
  }
  ._index-module__pageHeadingHeader__ACRne {
    margin-block-end: 0;
  }
  ._index-module__awardListContainer__xCsmQ {
    display: none;
  }
  ._index-module__planList__K3Zh- {
    display: block;
    grid-column: 3;
    grid-row: 1 / 3;
  }
  ._index-module__plansAndFeatures__Gi2aC {
    display: none;
  }
  ._index-module__featureCarousel__ADfTp {
    grid-column: 1 / 3;
    grid-row: 2;
  }
  ._index-module__transformationDivider__Lnuhj {
    --outgoing-divider-color: var(--cobalt-500-1000);
  }
}
@media (min-width: 84rem) {
  ._index-module__awardListContainer__xCsmQ {
    display: flex;
    justify-self: unset;
  }
}
@media (prefers-color-scheme: dark) {
  ._index-module__transformationContent__v-5Up {
    background-image: url(/build/_assets/transformation-section-wave-dark-Z4LAYSBP.svg);
  }
}

/* css-modules-plugin-ns:app/components/Badge.module.css?css-modules-plugin-compiled-css */
.Badge-module__badge__TB5s6 {
  --background: var(--cobalt-425);
  --color: #fff;
  padding: 0.1rem 0.5rem;
  text-transform: uppercase;
  border-radius: 0.3rem;
  background: var(--background);
  color: var(--color);
}
.Badge-module__zest__aQ1sv {
  --background: var(--zest-500);
  --color: var(--ink-750);
}
.Badge-module__cobalt__5iUG7 {
  --background: var(--cobalt-425);
}

/* css-modules-plugin-ns:app/components/plans/Plan.module.css?css-modules-plugin-compiled-css */
.Plan-module__plan__wzyx8 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 2px solid var(--cobalt-300);
  gap: 0.625rem;
  background: var(--cobalt-500);
  border-radius: 1.25rem;
  color: #fff;
  padding: 1rem;
  border: 2px solid var(--cobalt-300);
}
.Plan-module__planDetails__TAKrM {
  display: grid;
  inline-size: 100%;
  grid-template-areas: "body radio";
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  column-gap: 0.5rem;
}
.Plan-module__badges__zya-g {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-block-end: 0.3rem;
}
.Plan-module__badges__zya-g:empty {
  display: none;
}
.Plan-module__plan__wzyx8:has(input[type="radio"]:checked) {
  border-color: var(--zest-500);
}
.Plan-module__body__e-4N0 {
  grid-area: body;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.Plan-module__heading__g53JB {
  text-transform: capitalize;
  font-size: 1.625rem;
}
.Plan-module__pricePerMonth__7asv0 {
  color: var(--cobalt-150-250);
}
.Plan-module__freeTrialMessageContainer__kZA9O {
  border-top: solid 2px var(--cobalt-300);
  margin-block-start: 1rem;
  padding-block-start: 0.75rem;
  inline-size: 100%;
}
.Plan-module__freeTrialMessage__HuXhL {
  display: flex;
  gap: 1rem;
  align-items: center;
  background-image: url(/build/_assets/bell-GLXAWPSR.svg);
  background-repeat: no-repeat;
  background-position: center left;
  padding-inline-start: 40px;
  padding-block: 0.2rem;
}
.Plan-module__plan__wzyx8 input[type=radio] {
  justify-self: end;
  grid-area: radio;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  inline-size: 2rem;
  block-size: 2rem;
  border-radius: 100%;
  background: var(--cobalt-500-1000);
  border: 2px solid var(--cobalt-300);
  transition-property: background-color, border-color;
  transition-duration: 100ms;
  transition-timing-function: ease;
  display: grid;
  place-items: center;
}
.Plan-module__plan__wzyx8 input[type=radio]:focus-visible {
  outline: 2px solid var(--zest-500);
  outline-offset: 2px;
}
.Plan-module__plan__wzyx8 input[type=radio]:checked {
  background-color: var(--zest-500);
  border-color: var(--zest-500);
}
.Plan-module__plan__wzyx8 input[type=radio]:checked::after {
  content: "";
  display: block;
  margin-block-start: 1px;
  inline-size: 12px;
  block-size: 9px;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4L4.5 7.5L11 1' stroke='%2300398a' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
}
@media (min-width: 48rem) {
  .Plan-module__heading__g53JB {
    font-size: 1.875rem;
  }
}
@media (min-width: 75rem) {
  .Plan-module__heading__g53JB {
    font-size: 1.5rem;
  }
  .Plan-module__pricePerMonth__7asv0 {
    font-size: 1.125rem;
  }
}

/* css-modules-plugin-ns:app/components/plans/PlanPrice.module.css?css-modules-plugin-compiled-css */
.PlanPrice-module__wasPrice__0iCYF {
  color: var(--cobalt-150-250);
}
@media (min-width: 75rem) {
  .PlanPrice-module__pricePlanText__XHIfm {
    font-size: 1.125rem;
  }
}

/* css-modules-plugin-ns:app/components/plans/Plans.module.css?css-modules-plugin-compiled-css */
.Plans-module__plans__JZ9Uc {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.Plans-module__plans__JZ9Uc ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.Plans-module__plans__JZ9Uc button {
  align-self: flex-start;
}
.Plans-module__collapseToggleButton__wfNu- {
  appearance: none;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  color: var(--cobalt-500-white-0);
  text-decoration: underline;
  font-weight: 600;
}
@media (min-width: 75rem) {
  .Plans-module__plans__JZ9Uc ul {
    gap: 2rem;
  }
}

/* css-modules-plugin-ns:app/components/index-route/SelectPlanForm.module.css?css-modules-plugin-compiled-css */
.SelectPlanForm-module__selectPlans__yTRMI {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}
.SelectPlanForm-module__continue__mStQ7 {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}
.SelectPlanForm-module__planPriceDetails__iJBSC {
  text-align: start;
  padding: 1rem;
  background-color: var(--ink-100-600);
  border-radius: 1.25rem;
  color: var(--cobalt-500);
}
@media (prefers-color-scheme: dark) {
  .SelectPlanForm-module__planPriceDetails__iJBSC {
    background-color: #2f4b73;
    color: #fff;
  }
  .SelectPlanForm-module__continue__mStQ7 > button {
    background-color: var(--zest-500);
    color: var(--cobalt-500);
  }
  .SelectPlanForm-module__continue__mStQ7 > button:hover {
    background-color: var(--zest-600);
  }
}
@media (min-width: 75rem) {
  .SelectPlanForm-module__continue__mStQ7 > button {
    background-color: var(--zest-500);
    color: var(--cobalt-500);
  }
  .SelectPlanForm-module__continue__mStQ7 > button:hover {
    background-color: var(--zest-600);
  }
  .SelectPlanForm-module__continue__mStQ7 > button:focus-visible {
    outline-color: var(--zest-600);
  }
  .SelectPlanForm-module__selectPlans__yTRMI {
    gap: 2rem;
  }
  .SelectPlanForm-module__planPriceDetails__iJBSC {
    background-color: var(--cobalt-425);
    color: #fff;
  }
}

/* css-modules-plugin-ns:app/components/index-route/RedeemCoupon.module.css?css-modules-plugin-compiled-css */
.RedeemCoupon-module__applyCoupon__QEes5,
.RedeemCoupon-module__couponApplied__t2uT0 {
  padding-block: 1rem;
  border-block: 1px solid var(--ink-200-700);
}
.RedeemCoupon-module__removeCoupon__NRY3X {
  font-weight: 600;
}
.RedeemCoupon-module__couponApplied__t2uT0 {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.RedeemCoupon-module__couponAppliedCode__BwhqE {
  text-transform: uppercase;
}
.RedeemCoupon-module__applyCouponSummary__JrpWj {
  list-style: none;
  cursor: pointer;
  display: flex;
  gap: 1rem;
  align-items: center;
}
.RedeemCoupon-module__applyCouponSummary__JrpWj::-webkit-details-marker {
  display: none;
}
.RedeemCoupon-module__applyCouponSummaryText__6q6Dp,
.RedeemCoupon-module__couponAppliedText__tyqIH {
  flex-grow: 1;
}
.RedeemCoupon-module__arrowIcon__cYWEI,
.RedeemCoupon-module__checkIcon__aPLlW,
.RedeemCoupon-module__couponIconLine__DFzFY {
  stroke: var(--ink-750-white-0);
}
.RedeemCoupon-module__couponIconGlyph__lWgyX {
  fill: var(--ink-750-white-0);
}
.RedeemCoupon-module__couponApplied__t2uT0 .RedeemCoupon-module__couponIconLine__DFzFY,
.RedeemCoupon-module__couponApplied__t2uT0 .RedeemCoupon-module__checkIcon__aPLlW {
  stroke: var(--cobalt-500-250);
}
.RedeemCoupon-module__couponApplied__t2uT0 .RedeemCoupon-module__couponIconGlyph__lWgyX {
  fill: var(--cobalt-500-250);
}
.RedeemCoupon-module__applyCoupon__QEes5[open] .RedeemCoupon-module__arrowIcon__cYWEI {
  transform: rotate(180deg);
}
.RedeemCoupon-module__form__7kL7x {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  margin-block: 1rem;
  flex-wrap: wrap;
}
.RedeemCoupon-module__invalidCoupon__-Q7lu {
  flex: 1 0 100%;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.RedeemCoupon-module__invalidCoupon__-Q7lu,
.RedeemCoupon-module__form__7kL7x:has(.RedeemCoupon-module__invalidCoupon__-Q7lu) .RedeemCoupon-module__couponLabel__L-u9e {
  color: var(--error-red);
}
.RedeemCoupon-module__warningIcon__dsr1G {
  fill: var(--error-red);
}
.RedeemCoupon-module__couponField__7YfSi {
  flex: 1 0 auto;
}
.RedeemCoupon-module__couponLabel__L-u9e {
  font-size: 1.125rem;
}
.RedeemCoupon-module__couponInput__IETh5 {
  padding-block: 0.7rem;
}
@media (min-width: 75rem) {
  .RedeemCoupon-module__applyCoupon__QEes5,
  .RedeemCoupon-module__couponApplied__t2uT0 {
    border-block: 1px solid var(--cobalt-300);
  }
  .RedeemCoupon-module__couponLabel__L-u9e {
    color: #fff;
  }
  .RedeemCoupon-module__couponApplied__t2uT0 .RedeemCoupon-module__couponIconLine__DFzFY,
  .RedeemCoupon-module__couponApplied__t2uT0 .RedeemCoupon-module__checkIcon__aPLlW,
  .RedeemCoupon-module__arrowIcon__cYWEI,
  .RedeemCoupon-module__checkIcon__aPLlW,
  .RedeemCoupon-module__couponIconLine__DFzFY {
    stroke: #fff;
  }
  .RedeemCoupon-module__couponIconGlyph__lWgyX {
    fill: #fff;
  }
  .RedeemCoupon-module__formButton__452MG {
    background-color: var(--zest-500);
    color: var(--cobalt-500);
  }
  .RedeemCoupon-module__formButton__452MG:hover {
    background: var(--zest-600);
  }
  .RedeemCoupon-module__formButton__452MG:focus-visible {
    outline-color: var(--zest-600);
  }
  .RedeemCoupon-module__couponInput__IETh5:focus-visible {
    border-color: var(--ink-750-white-0);
  }
}
@media (min-width: 75rem) {
  .RedeemCoupon-module__invalidCoupon__-Q7lu,
  .RedeemCoupon-module__form__7kL7x:has(.RedeemCoupon-module__invalidCoupon__-Q7lu) .RedeemCoupon-module__couponLabel__L-u9e {
    color: #fff;
  }
  .RedeemCoupon-module__warningIcon__dsr1G {
    fill: #fff;
  }
  .RedeemCoupon-module__removeCoupon__NRY3X {
    color: inherit;
  }
}

/* css-modules-plugin-ns:app/components/plans/PlanSkeleton.module.css?css-modules-plugin-compiled-css */
.Plan-module__plan__wzyx8 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 2px solid var(--cobalt-300);
  gap: 0.625rem;
  background: var(--cobalt-500);
  border-radius: 1.25rem;
  color: #fff;
  padding: 1rem;
  border: 2px solid var(--cobalt-300);
}
.Plan-module__planDetails__TAKrM {
  display: grid;
  inline-size: 100%;
  grid-template-areas: "body radio";
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  column-gap: 0.5rem;
}
.Plan-module__badges__zya-g {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-block-end: 0.3rem;
}
.Plan-module__badges__zya-g:empty {
  display: none;
}
.Plan-module__plan__wzyx8:has(input[type="radio"]:checked) {
  border-color: var(--zest-500);
}
.Plan-module__body__e-4N0 {
  grid-area: body;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.Plan-module__heading__g53JB {
  text-transform: capitalize;
  font-size: 1.625rem;
}
.Plan-module__pricePerMonth__7asv0 {
  color: var(--cobalt-150-250);
}
.Plan-module__freeTrialMessageContainer__kZA9O {
  border-top: solid 2px var(--cobalt-300);
  margin-block-start: 1rem;
  padding-block-start: 0.75rem;
  inline-size: 100%;
}
.Plan-module__freeTrialMessage__HuXhL {
  display: flex;
  gap: 1rem;
  align-items: center;
  background-image: url(/build/_assets/bell-GLXAWPSR.svg);
  background-repeat: no-repeat;
  background-position: center left;
  padding-inline-start: 40px;
  padding-block: 0.2rem;
}
.Plan-module__plan__wzyx8 input[type=radio] {
  justify-self: end;
  grid-area: radio;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  inline-size: 2rem;
  block-size: 2rem;
  border-radius: 100%;
  background: var(--cobalt-500-1000);
  border: 2px solid var(--cobalt-300);
  transition-property: background-color, border-color;
  transition-duration: 100ms;
  transition-timing-function: ease;
  display: grid;
  place-items: center;
}
.Plan-module__plan__wzyx8 input[type=radio]:focus-visible {
  outline: 2px solid var(--zest-500);
  outline-offset: 2px;
}
.Plan-module__plan__wzyx8 input[type=radio]:checked {
  background-color: var(--zest-500);
  border-color: var(--zest-500);
}
.Plan-module__plan__wzyx8 input[type=radio]:checked::after {
  content: "";
  display: block;
  margin-block-start: 1px;
  inline-size: 12px;
  block-size: 9px;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4L4.5 7.5L11 1' stroke='%2300398a' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
}
@media (min-width: 48rem) {
  .Plan-module__heading__g53JB {
    font-size: 1.875rem;
  }
}
@media (min-width: 75rem) {
  .Plan-module__heading__g53JB {
    font-size: 1.5rem;
  }
  .Plan-module__pricePerMonth__7asv0 {
    font-size: 1.125rem;
  }
}
.PlanSkeleton-module__skeleton__FCGs2 {
  display: flex;
  border: none;
  background: var(--ink-750-white-0);
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  position: relative;
  overflow: hidden;
  opacity: 0.1;
}
.PlanSkeleton-module__skeleton__FCGs2::after {
  content: "";
  display: block;
  position: absolute;
  inset: 0;
  background: linear-gradient(-60deg, #fff0 0, #fff0 10%, #fff, #fff0 90%, #fff0 100%);
  transform: translateX(-100%);
  animation: PlanSkeleton-module__shimmer__fjI6p 1.5s infinite ease;
  opacity: 0.5;
}
.PlanSkeleton-module__title__fkyYJ,
.PlanSkeleton-module__price__yPMV2 {
  background: var(--white-0-ink-750);
  border-radius: 0.4rem;
}
.PlanSkeleton-module__title__fkyYJ {
  block-size: 1.8rem;
  inline-size: 8.3rem;
}
.PlanSkeleton-module__price__yPMV2 {
  block-size: 1.5rem;
  inline-size: 6.2rem;
}
@keyframes PlanSkeleton-module__shimmer__fjI6p {
  100% {
    transform: translateX(100%);
  }
}

/* css-modules-plugin-ns:app/components/index-route/PlanList.module.css?css-modules-plugin-compiled-css */
.PlanList-module__noPlanSelectedError__Z9WjK {
  text-align: start;
  color: #fff;
  padding-block: 1rem;
}

/* css-modules-plugin-ns:app/components/Carousel.module.css?css-modules-plugin-compiled-css */
.Carousel-module__carousel__5T8Nt {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  flex-direction: row;
  gap: 2rem;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.Carousel-module__carouselItem__RtFPf {
  display: flex;
  inline-size: var(--item-width);
  block-size: var(--item-height);
}

/* css-modules-plugin-ns:app/components/index-route/TransformationCarousel.module.css?css-modules-plugin-compiled-css */
.TransformationCarousel-module__transformationCarousel__sOOJK {
  position: relative;
  z-index: 1;
}
.TransformationCarousel-module__transformationItem__tefGE {
  border-radius: 22px;
  inline-size: var(--item-width);
  block-size: var(--item-height);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0;
  z-index: 2;
  background-color: var(--white-0-ink-750);
  color: var(--ink-750-white-0);
  overflow: hidden;
}
.TransformationCarousel-module__transformationBlock__OJQgK {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  block-size: 100%;
}
.TransformationCarousel-module__transformationQuote__Eg9d3 {
  padding-inline: 1.5rem;
  padding-block-start: 4rem;
  padding-block-end: 0;
  background-image: url(/build/_assets/quotes-light-YCCXFLB4.svg);
  background-repeat: no-repeat;
  background-position: 1.5rem 2rem;
}
.TransformationCarousel-module__transformationText__tXwv5 {
  padding-block: 0.8rem;
  font-size: 1.05rem;
}
.TransformationCarousel-module__transformationName__Q4xQ7 {
  color: var(--cobalt-500-250);
  padding-block-end: 1rem;
  font-size: 1.05rem;
}
.TransformationCarousel-module__transformationImageContainer__eqq-p {
  position: relative;
  inline-size: 100%;
}
.TransformationCarousel-module__transformationImageWave__SXKxT {
  position: absolute;
  right: 0;
  display: block;
  inline-size: 100%;
  block-size: 3.375rem;
  background-image: url(/build/_assets/transformation-wave-light-X6G4REOW.svg);
  background-repeat: no-repeat;
  background-position: left bottom;
  z-index: 2;
}
.TransformationCarousel-module__transformationImage__2dNsw {
  min-inline-size: 100%;
}
@media (min-width: 85rem) {
  .TransformationCarousel-module__transformationCarousel__sOOJK {
    display: flex;
    overflow: visible;
    justify-content: space-between;
  }
}
@media (prefers-color-scheme: dark) {
  .TransformationCarousel-module__transformationQuote__Eg9d3 {
    background-image: url(/build/_assets/quotes-dark-33ELOR7B.svg);
  }
  .TransformationCarousel-module__transformationImageWave__SXKxT {
    background-image: url(/build/_assets/transformation-wave-dark-STTIOLUF.svg);
  }
}

/* css-modules-plugin-ns:app/components/index-route/AppRating.module.css?css-modules-plugin-compiled-css */
.AppRating-module__appRating__Ol0Os {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  text-transform: uppercase;
}
.AppRating-module__appRatingNumber__4kLWt {
  font-family: "Obviously", sans-serif;
}
.AppRating-module__stars__suqBx {
  display: flex;
}

/* css-modules-plugin-ns:app/components/index-route/AwardsList.module.css?css-modules-plugin-compiled-css */
.AwardsList-module__awardsList__NxZwP {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  inline-size: 20.5rem;
}
.AwardsList-module__award__TkQtR {
  inline-size: 5.94rem;
  block-size: 4.68rem;
}
.AwardsList-module__separator__ifM2F {
  display: inline-block;
  inline-size: 0.063rem;
  block-size: 2.83rem;
  border-inline-end: solid 1px var(--cobalt-400-800);
}

/* css-modules-plugin-ns:app/components/index-route/FeatureCarousel.module.css?css-modules-plugin-compiled-css */
.FeatureCarousel-module__featuresCarousel__ZQr4m {
  margin-block: 0;
  padding-block-start: 3rem;
}
.FeatureCarousel-module__featureItem__oH1e- {
  background-image: url(/build/_assets/feature-hite-WZF5VDGJ.svg);
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: top left;
  background-color: var(--cobalt-425);
  font-family: "Obviously", sans-serif;
  padding: 0;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 22px;
  inline-size: var(--item-width);
  block-size: var(--item-height);
}
.FeatureCarousel-module__carouselTitle__Ppnlu {
  font-size: 0.875rem;
  text-align: center;
  padding: 1.5rem;
}
.FeatureCarousel-module__carouselImage__ORvGu {
  inline-size: 9.6rem;
  max-inline-size: 9.6rem;
  block-size: 17rem;
  max-block-size: 17rem;
  align-self: center;
}
.FeatureCarousel-module__imageOnly__q6-Oi {
  padding: 0;
  .FeatureCarousel-module__carouselTitle__Ppnlu { display: none; } .FeatureCarousel-module__carouselImage__ORvGu { inline-size: 100%; block-size: 100%; position: relative; inset-block-end: 0; };
}
@media (min-width: 48rem) {
  .FeatureCarousel-module__featuresCarousel__ZQr4m {
    :nth-child(n + 4) { display: none; } justify-content: center;
  }
}
@media (min-width: 56.375rem) {
  .FeatureCarousel-module__featuresCarousel__ZQr4m {
    :nth-child(n + 4) { display: flex; } padding-inline: 0;
    overflow: visible;
    gap: 1rem;
  }
}
@media (min-width: 75rem) {
  .FeatureCarousel-module__featuresCarousel__ZQr4m {
    justify-content: flex-start;
    :nth-child(n + 4) { display: none; } gap: 2rem;
  }
}
@media (min-width: 84rem) {
  .FeatureCarousel-module__featuresCarousel__ZQr4m {
    :nth-child(n + 4) { display: flex; } padding-inline: 0;
    overflow: visible;
  }
}

/* css-modules-plugin-ns:app/components/pageHeader/PageHeaderTitle.module.css?css-modules-plugin-compiled-css */
.PageHeaderTitle-module__title__MmD3E {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media (min-width: 48rem) {
  .PageHeaderTitle-module__title__MmD3E {
    flex-direction: row;
    justify-content: space-between;
    grid-column: 1/3;
  }
}
@media (min-width: 56.375rem) {
  .PageHeaderTitle-module__title__MmD3E {
    grid-column: 1;
  }
}
@media (min-width: 75rem) {
  .PageHeaderTitle-module__title__MmD3E {
    grid-column: 1;
    grid-row: 1;
  }
}
/*# sourceMappingURL=/build/css-bundle-24QWMM6F.css.map */
