.PageHeaderTitle-module__title__MmD3E {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media (min-width: 48rem) {
  .PageHeaderTitle-module__title__MmD3E {
    flex-direction: row;
    justify-content: space-between;
    grid-column: 1/3;
  }
}

@media (min-width: 56.375rem) {
  .PageHeaderTitle-module__title__MmD3E {
    grid-column: 1;
  }
}

@media (min-width: 75rem) {
  .PageHeaderTitle-module__title__MmD3E {
    grid-column: 1;
    grid-row: 1;
  }
}
