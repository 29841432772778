.Text-module__sm__t84ae {
  font-size: 0.875rem;
}

.Text-module__md__IPpti {
  font-size: 1rem;
}

.Text-module__lg__LHcPS {
  font-size: 1.125rem;
}

.Text-module__xl__CnNx2 {
  font-size: 1.25rem;
}

.Text-module__xxl__YEoNX {
  font-size: 1.5rem;
}

.Text-module__xxxl__P-3Ag {
  font-size: 1.75rem;
}

.Text-module__w400__79VdL {
  font-weight: 400;
}

.Text-module__w500__ihpD- {
  font-weight: 500;
}

.Text-module__w600__3AuP0 {
  font-weight: 600;
}

@media (min-width: 48rem) {
  .Text-module__sm__t84ae {
    font-size: 1rem;
  }

  .Text-module__lg__LHcPS {
    font-size: 1.25rem;
  }

  .Text-module__xl__CnNx2 {
    font-size: 1.5rem;
  }
}
