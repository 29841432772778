:where(.PageHeaderBody-module__body__-KVzw) {
  max-inline-size: var(--max-page-inline-size-narrow);
  padding-inline: var(--outer-gutter);
  gap: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  inline-size: 100%;
  z-index: 1;
}
