.AwardsList-module__awardsList__NxZwP {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  inline-size: 20.5rem;
}

.AwardsList-module__award__TkQtR {
  inline-size: 5.94rem;
  block-size: 4.68rem;
}

.AwardsList-module__separator__ifM2F {
  display: inline-block;
  inline-size: 0.063rem;
  block-size: 2.83rem;
  border-inline-end: solid 1px var(--cobalt-400-800);
}
