.AppRating-module__appRating__Ol0Os {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  text-transform: uppercase;
}

.AppRating-module__appRatingNumber__4kLWt {
  font-family: 'Obviously', sans-serif;
}

.AppRating-module__stars__suqBx {
  display: flex;
}
