.Checkbox-module__checkbox__Xi-nt {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
}

.Checkbox-module__checkbox__Xi-nt input[type='checkbox'] {
  appearance: none;
  margin: 0;
  flex-shrink: 0;
  inline-size: 1.5rem;
  block-size: 1.5rem;
  background: var(--ink-50-550);
  border: 2px solid var(--ink-300);
  border-radius: 0.375rem;
  display: grid;
  place-items: center;
  transition: 100ms ease;
}

.Checkbox-module__checkbox__Xi-nt input[type='checkbox']:checked {
  background-color: var(--cobalt-500-white-0);
  border-color: var(--cobalt-500-white-0);
}

.Checkbox-module__checkbox__Xi-nt input[type='checkbox']:checked::after {
  content: '';
  display: block;
  margin-top: 1px; /* sorts out slight vertical mis-alignment */
  inline-size: 12px;
  block-size: 9px;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4L4.5 7.5L11 1' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
}

@media (prefers-color-scheme: dark) {
  .Checkbox-module__checkbox__Xi-nt input[type='checkbox']:checked::after {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4L4.5 7.5L11 1' stroke='%230053c7' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  }
}

.Checkbox-module__checkbox__Xi-nt a {
  color: inherit;
  font-weight: 600;
  text-decoration: underline;
}

.Checkbox-module__checkbox__Xi-nt a:hover {
  color: var(--cobalt-500-white-0);
}
