.PageHeaderMessage-module__pageHeaderMessage__X3RhQ {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  border: 1px solid var(--cobalt-400-800);
  padding: 1rem 0.6rem;
  border-radius: 1.125rem;
  background-color: var(--cobalt-500-1000);
  color: var(--cobalt-50);
}

.PageHeaderMessage-module__icon__xqxN9 {
  inline-size: 2.25rem;
  flex-shrink: 0;
}

.PageHeaderMessage-module__icon__xqxN9 path,
.PageHeaderMessage-module__icon__xqxN9 circle {
  stroke: currentColor;
}
