.root-module__root__vBkJw {
  /* Colours */
  --cobalt-50: #e1eeff;
  --cobalt-50-550: #e1eeff;
  --cobalt-150: #c2daff;
  --cobalt-150-250: #c2daff;
  --cobalt-300: #609df1;
  --cobalt-400-800: #4a91f3;
  --cobalt-425: #1368de;
  --cobalt-450-1050: #0e60d2;
  --cobalt-500: #0053c7;
  --cobalt-500-white-0: #0053c7;
  --cobalt-500-250: #0053c7;
  --cobalt-500-1000: #0053c7;
  --cobalt-850: #1050ab;
  --cobalt-900: #1a4d94;
  --cobalt-100-900: #cce2ff;
  --ink-100-600: #edf3fb;
  --ink-50-550: #f6f9fd;
  --ink-200-700: #e6eef9;
  --ink-300: #b2c2d9;
  --ink-525: #60769a;
  --ink-525-500: #60769a;
  --ink-525-white-0: #60769a;
  --ink-750-white-0: #18335b;
  --ink-750: #18335b;
  --ink-750-950: #18335b;
  --white-0-ink-750: #fff;
  --aqua-500: #00cfda;
  --blush-500: #f6c9c7;
  --zest-500: #d7fd8c;
  --zest-600: #cbf971;
  --error-red: #eb5743;

  /* Dimensions */
  /* Internal gutter between columns */
  --gutter: 2rem;
  /* Gutter for the far left and right of grid rows */
  --outer-gutter: 1.5rem;
  --max-page-inline-size: calc(80rem + (var(--outer-gutter) * 2));
  --max-page-inline-size-narrow: calc(37.5rem + (var(--outer-gutter) * 2));

  font-size: 16px;
  font-family: 'QanelasSoft', sans-serif;
  line-height: 1.375;
  color: var(--ink-750-white-0);
  background: var(--white-0-ink-750);
  display: flex;
  flex-direction: column;
  accent-color: var(--cobalt-500-white-0);
  -webkit-font-smoothing: antialiased;
}

.root-module__page__9g5fU {
  flex: 1;
  display: flex;
}

.root-module__page__9g5fU > * {
  flex: 1;
}

a {
  color: var(--cobalt-500-white-0);
}

@media (prefers-color-scheme: dark) {
  .root-module__root__vBkJw {
    --cobalt-50-550: #275faf;
    --cobalt-150-250: #8bb9ff;
    --cobalt-400-800: #1859b6;
    --cobalt-450-1050: #03347a;
    --cobalt-500-white-0: #fff;
    --cobalt-500-250: #8bb9ff;
    --cobalt-500-1000: #00398a;
    --cobalt-100-900: #1a4d94;
    --ink-50-550: #25426c;
    --ink-200-700: #38567d;
    --ink-525-white-0: #fff;
    --ink-525-500: #90a4c5;
    --ink-525-white-0: #fff;
    --ink-750-white-0: #fff;
    --ink-750-950: #112b51;
    --white-0-ink-750: #18335b;
    --error-red: #ff7968;
  }
}

@media (min-width: 24rem) {
  .root-module__root__vBkJw {
    --outer-gutter: 2rem;
  }
}

@media (min-width: 48rem) {
  .root-module__root__vBkJw {
    --outer-gutter: 5rem;
  }
}
