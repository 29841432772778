.Carousel-module__carousel__5T8Nt {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  flex-direction: row;
  gap: 2rem;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.Carousel-module__carouselItem__RtFPf {
  display: flex;
  inline-size: var(--item-width);
  block-size: var(--item-height);
}
