.FireworksAnimation-module__fireworks__AKSXa {
  position: absolute;
  inset-block-start: -200px;
  inset-inline-start: -20px;
  inline-size: 50%;
  max-inline-size: 200px;
}

.FireworksAnimation-module__fireworks__AKSXa:last-child {
  inset-block-start: -180px;
  inset-inline-start: auto;
  inset-inline-end: -20px;
}

@media (prefers-reduced-motion: reduce) {
  .FireworksAnimation-module__fireworks__AKSXa {
    display: none;
  }
}

@media (min-width: 48em) {
  .FireworksAnimation-module__fireworks__AKSXa {
    inset-inline-start: 0;
    inset-block-start: -100px;
  }

  .FireworksAnimation-module__fireworks__AKSXa:last-child {
    inset-inline-end: 0;
    inset-block-start: -80px;
  }
}
