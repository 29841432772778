.SelectPlanForm-module__selectPlans__yTRMI {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.SelectPlanForm-module__continue__mStQ7 {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.SelectPlanForm-module__planPriceDetails__iJBSC {
  text-align: start;
  padding: 1rem;
  background-color: var(--ink-100-600);
  border-radius: 1.25rem;
  color: var(--cobalt-500);
}

@media (prefers-color-scheme: dark) {
  .SelectPlanForm-module__planPriceDetails__iJBSC {
    background-color: #2f4b73;
    color: #fff;
  }

  .SelectPlanForm-module__continue__mStQ7 > button {
    background-color: var(--zest-500);
    color: var(--cobalt-500);
  }

  .SelectPlanForm-module__continue__mStQ7 > button:hover {
    background-color: var(--zest-600);
  }
}

/* Desktop - Small (1200px) */
@media (min-width: 75rem) {
  .SelectPlanForm-module__continue__mStQ7 > button {
    background-color: var(--zest-500);
    color: var(--cobalt-500);
  }
  .SelectPlanForm-module__continue__mStQ7 > button:hover {
    background-color: var(--zest-600);
  }

  .SelectPlanForm-module__continue__mStQ7 > button:focus-visible {
    outline-color: var(--zest-600);
  }

  .SelectPlanForm-module__selectPlans__yTRMI {
    gap: 2rem;
  }

  .SelectPlanForm-module__planPriceDetails__iJBSC {
    background-color: var(--cobalt-425);
    color: #fff;
  }
}
