.ErrorPage-module__page__Rv5ug {
  background: var(--ink-50-550);
  padding: 3.5rem;
}

.ErrorPage-module__body__4Xiqi {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  text-align: center;
  max-inline-size: 32rem;
  margin-inline-start: auto;
  margin-inline-end: auto;
}

.ErrorPage-module__heading__rNor3 {
  color: var(--cobalt-500-white-0);
}

@media (min-width: 48rem) {
  .ErrorPage-module__page__Rv5ug {
    padding: 5.625rem;
  }
}
