.Heading-module__h1__HaaMh,
.Heading-module__h2__8vNhp,
.Heading-module__h3__AV0hZ {
  font-family: 'Obviously', sans-serif;
  font-weight: 600;
  line-height: 1.1;
}

.Heading-module__h1__HaaMh {
  font-size: 1.75rem;
}

.Heading-module__h2__8vNhp {
  font-size: 1.5rem;
}

.Heading-module__h3__AV0hZ {
  font-size: 1.25rem;
  letter-spacing: 0;
}

@media (min-width: 48rem) {
  .Heading-module__h1__HaaMh {
    font-size: 2.75rem;
  }

  .Heading-module__h2__8vNhp {
    font-size: 2rem;
  }

  .Heading-module__h3__AV0hZ {
    font-size: 1.6rem;
  }
}
