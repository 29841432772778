.PageHeaderCopy-module__copy__NN5sp {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  position: relative;
  z-index: 1;
}

@media (min-width: 48rem) {
  .PageHeaderCopy-module__copy__NN5sp {
    gap: 0.75rem;
  }
}

@media (min-width: 56.375rem) {
  .PageHeaderCopy-module__copy__NN5sp {
    background-image: none;
    padding-inline-end: 0;
  }
}
