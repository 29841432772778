.TransformationCarousel-module__transformationCarousel__sOOJK {
  position: relative;
  z-index: 1;
}

.TransformationCarousel-module__transformationItem__tefGE {
  border-radius: 22px;
  inline-size: var(--item-width);
  block-size: var(--item-height);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0;
  z-index: 2;
  background-color: var(--white-0-ink-750);
  color: var(--ink-750-white-0);
  overflow: hidden;
}

.TransformationCarousel-module__transformationBlock__OJQgK {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  block-size: 100%;
}

.TransformationCarousel-module__transformationQuote__Eg9d3 {
  padding-inline: 1.5rem;
  padding-block-start: 4rem;
  padding-block-end: 0;
  background-image: url('../../../public/img/quotes-light.svg');
  background-repeat: no-repeat;
  background-position: 1.5rem 2rem;
}

.TransformationCarousel-module__transformationText__tXwv5 {
  padding-block: 0.8rem;
  font-size: 1.05rem;
}

.TransformationCarousel-module__transformationName__Q4xQ7 {
  color: var(--cobalt-500-250);
  padding-block-end: 1rem;
  font-size: 1.05rem;
}

.TransformationCarousel-module__transformationImageContainer__eqq-p {
  position: relative;
  inline-size: 100%;
}

.TransformationCarousel-module__transformationImageWave__SXKxT {
  position: absolute;
  right: 0;
  display: block;
  inline-size: 100%;
  block-size: 3.375rem;
  background-image: url('../../../public/img/transformation-wave-light.svg');
  background-repeat: no-repeat;
  background-position: left bottom;
  z-index: 2;
}

.TransformationCarousel-module__transformationImage__2dNsw {
  min-inline-size: 100%;
}

@media (min-width: 85rem) {
  .TransformationCarousel-module__transformationCarousel__sOOJK {
    display: flex;
    overflow: visible;
    justify-content: space-between;
  }
}

@media (prefers-color-scheme: dark) {
  .TransformationCarousel-module__transformationQuote__Eg9d3 {
    background-image: url('../../../public/img/quotes-dark.svg');
  }

  .TransformationCarousel-module__transformationImageWave__SXKxT {
    background-image: url('../../../public/img/transformation-wave-dark.svg');
  }
}
