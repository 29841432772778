.payment-module__body__i9Twx {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.payment-module__payment__93tbm {
  inline-size: 100%;
  max-inline-size: 39rem;
}
